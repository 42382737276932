import React from "react"
import "./index.scss"
import Box from "../box"
import Container from "../container"
import { useCountUp } from 'react-countup'

const ThisMonth = () => {

  const currentDate = new Date();
  const currentWeek = currentDate.getDate() / 7;
  const workdays = currentDate.getDate() - (currentWeek * 2);
  const oakies = 16;
  const developers = 10;

  // Coffee
  const coffeesPerDay = 3;
  const coffeeCounter = oakies * coffeesPerDay * workdays;

  // Abfahrt
  const abfahrtCounter = currentWeek * 2;

  // Alexa
  const alexaCounter = currentWeek + 4;

  // Telefonate
  const callsPerDay = 1.2;
  const callCounter = callsPerDay * (oakies - developers) * workdays;

  // Meetings
  const meetingCounter = 0;

  const locPerDay = 50;
  const locCounter = (developers * locPerDay) * workdays;

  const [ start, setStart ] = React.useState(false)

  return (
    <Container
      justify={"right"}
      appear={"fromLeft"}
      onAppear={ () => setStart(true) }
    >
      <Box
          padding={"full"}
          background={"gray-light"}>
        <div className="this-month-container">
          <h2>Diesen Monat</h2>
          <div className="this-month-counter-container">
            <Counter counter={coffeeCounter} text={"Tassen Kaffee"} start={start} />
            <Counter counter={abfahrtCounter} text={"Builds fehlgeschlagen"} start={start} />
            <Counter counter={alexaCounter} text={'"Alexa?!"  gerufen'} start={start} />
            <Counter counter={callCounter} text={"Telefonate"} start={start} />
            <Counter counter={meetingCounter} text={"sinnlose Meetings"} start={start} />
            <Counter counter={locCounter} text={"Zeilen Code geschrieben"} start={start} />
          </div>
        </div>
      </Box>
    </Container>
  )
};

const Counter = ({ counter, text, start }) => {

  const [ counting, setCounting ] = React.useState(false)

  const countUpHook = useCountUp({
    startOnMount: false,
    end: counter,
    delay: 5,
    duration: 5,
  })

  if(start && !(counting)) {
    countUpHook.start()
    setCounting(true)
  }

  return (
    <div className="this-month-counter">
      <p className="counter">{countUpHook.countUp}</p>
      <p className="text">{text}</p>
    </div>
  )
}

export default ThisMonth;