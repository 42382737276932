import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import Section from "../components/section"
import Container from "../components/container"
import Box from "../components/box"
import Image from "../components/image"
import ButtonLink from "../components/button/link"
import ThisMonth from "../components/thisMonth"
import HeaderSection from "../components/header";
import AvatarListSection from "../components/avatar/section/list";
import AvatarModalSection from "../components/avatar/section/modal";

const Index = ({_path, data}) => {
    return (
        <Layout path={"/"}>
            <SEO title="Home"/>
            {/*<HeaderSection*/}
            {/*    title={"Einfach machen!"}*/}
            {/*    shortTitle={"Red Oak"}*/}
            {/*    image={data.headerImage.childImageSharp}*/}
            {/*    type={"image-fullscreen"}*/}
            {/*/>*/}
            <NoBullshitSection/>
            <WhoWeAreSection image={data.whoWeAreImage.childImageSharp}/>
            <DoingSection/>
            <HowWeWorkSection image={data.howWeWorkImage.childImageSharp}/>
            <AvatarSection
                people={data.peopleAllMdx.edges}
            />
            <ThisMonthSection/>
            <ContactSection image={data.contactImage.childImageSharp}/>

        </Layout>
    )
}

const NoBullshitSection = () => (
    <Section id={"NoBullshitSection"}>
        <Container appear={"fromBottom"}>
            <Box alignText={"center"} sizeText={"bigger"}>
                <p style={{borderTop:"solid gray 1.5px", paddingTop:"40px"}}>
                    <span>Kein agiles Bullshit-Bingo.<br/></span>
                    <span>Kein Business Buzzwording.</span>
                </p>
                <span className={"text-color-red text-weight-bold"}>
          &ndash;<br/>
        </span>
                <p>
                    <span>Stattdessen </span>
                    <span className={"text-color-red text-weight-bold"}>
            ehrliche Softwareentwicklung.
          </span>
                </p>
            </Box>
        </Container>
    </Section>
)

const WhoWeAreSection = ({image}) => (
    <Section id={"WhoWeAreSection"}>
        <Container alignItems={"center"} appear={"fromLeft"}>
            <Box>
                <Image fluid={image.fluid} alt={"Über uns"}/>
            </Box>
            <Box
                border={"left-overlay"}
                borderColor={"red"}
                padding={"horizontal"}
            >
                <h2>Wer wir sind</h2>
                <p>
                    Red Oak Consulting, ein buntes Team aus Entwicklern, Analysten, Hobbybäckern, Fußballfans,
                    Excel-Fanatikern
                    und vor allem: softwarebegeisterten Menschen.
                </p>
                <ButtonLink to={"/ueber-uns"}>Erfahre hier mehr</ButtonLink>
            </Box>
        </Container>
    </Section>
)

const DoingSection = () => (
    <Section id={"DoingSection"}>
        <Container justify={"left"} appear={"fromLeft"}>
            <Box
                border={"left"}
                borderColor={"red"}
                padding={"horizontal"}
            >
                <h2>was wir machen...</h2>
                <ul>
                    <li>Software entwickeln</li>
                    <li>Funktionierende Prototypen entwickeln</li>
                    <li>Cobol hacken</li>
                    <li>Projekte managen</li>
                    <li>Coaching und Schulungen geben</li>
                </ul>
            </Box>
        </Container>
        <Container justify={"right"} appear={"fromRight"}>
            <Box
                border={"right"}
                borderColor={"red"}
                padding={"horizontal"}
                alignText={"right"}
            >
                <h2>...und was nicht</h2>
                <ul>
                    <li>Starren Prozessen folgen</li>
                    <li>Sinnlos in Meetings hängen</li>
                    <li>Einzelkämpfer sein</li>
                    <li>Schuld zuweisen</li>
                    <li>Bier im Kühlschrank vergessen</li>
                    <li>Geheimniskrämerei</li>
                </ul>
            </Box>
        </Container>
    </Section>
)

const HowWeWorkSection = ({image}) => (
    <Section id={"HowWeWorkSection"}>
        <Container alignItems={"center"} appear={"fromRight"}>
            <Box invisibleFrom={"md"}>
                <Image fluid={image.fluid} alt={"Über uns"}/>
            </Box>
            <Box
                border={"right-overlay"}
                borderColor={"red"}
                padding={"horizontal"}
            >
                <h2>Wie wir arbeiten</h2>
                <p>
                    Wir sehen ein Problem, betrachten das große Ganze und finden eine passende Lösung. Das macht für uns
                    gute
                    Arbeit aus. Direkt, offen und ehrlich.
                </p>
                <ButtonLink to={"/leistungen"}>unsere Leistungen</ButtonLink>
            </Box>
            <Box invisibleTo={"sm"}>
                <Image fluid={image.fluid} alt={"Über uns"}/>
            </Box>
        </Container>
    </Section>
)

const AvatarSection = ({people}) => {

    const [personIndex, setPersonIndex] = React.useState(null);

    const peopleWithPopup = people
        .filter(person => person.node.frontmatter.popup)
        .map(person => person.node.frontmatter.position);
    const openPerson = (positionOfPerson) => {

        const indexOfPerson = peopleWithPopup.indexOf(positionOfPerson);

        if (window.personCarousel && indexOfPerson !== -1) {
            window.personCarousel.slickGoTo(indexOfPerson, true)
        }
        setPersonIndex(positionOfPerson)
    }
    const closePerson = () => setPersonIndex(null)

    return (
        <Section>
            <AvatarListSection
                people={people}
                openPerson={openPerson}
                playAnimations={personIndex === null}
            />
            <AvatarModalSection
                people={people}
                closePerson={closePerson}
                personIndex={personIndex}/>
        </Section>
    )
}

const ThisMonthSection = () => (
    <Section id={"ThisMonthSection"}>
        <ThisMonth/>
    </Section>
)

const ContactSection = ({image}) => (
    <Section id={"ContactSection"}>
        <Container alignItems={"center"} appear={"fromLeft"}>
            <Box>
                <Image fluid={image.fluid} alt={"Unsere Standorte"}/>
            </Box>
            <Box
                border={"left-overlay"}
                borderColor={"red"}
                padding={"horizontal"}
            >
                <h2>Unsere Standorte</h2>
                <h3>Oldenburg</h3>
                <p>
                    Lange Straße 6<br/>
                    26122 Oldenburg
                </p>
                <h3>Braunschweig</h3>
                <p>
                    Westbahnhof 11<br/>
                    38118 Braunschweig
                </p>
                <ButtonLink to={"/karriere"}>freie Stellen</ButtonLink>
            </Box>
        </Container>
    </Section>
)

export default Index

export const query = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                title
            }
        }
        headerImage: file(relativePath: {eq: "landing/startbild.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        whoWeAreImage: file(relativePath: {eq: "landing/about.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        howWeWorkImage: file(relativePath: {eq: "landing/services.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        contactImage: file(relativePath: {eq: "landing/career.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        peopleAllMdx: allMdx(
            filter: { fileAbsolutePath: { regex: "/people/" } }
            sort: { order: ASC, fields: [frontmatter___position] }
        ) {
            edges {
                node {
                    id
                    body
                    frontmatter {
                        position
                        popup
                        firstName
                        lastName
                        jobTitle
                        avatar
                        animation
                        portrait {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        landscape {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`